import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { ToastController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { ImageUploadService } from './services/image-upload.service';
import { OcpiService } from './services/ocpi.service';
import { TrariffService } from './services/tariff.service';
import { UserService } from './services/user.service';
import { TokenService } from './shared/token.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { AppVersion } from '@ionic-native/app-version/ngx';

declare var Pushy: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  public appPages: any[] = [
    {
      title: 'My Profile',
      url: '/profile',
    },
    {
      title: 'Activie Sessions',
      url: '',
    },
    {
      title: 'My Vehicles',
      url: '/my-vehicle',
    },
    {
      title: 'Wallet',
      url: '/wallet',
    },
    {
      title: 'Favorites',
      url: '/tabs/tab3',
    },
    {
      title: 'My Usage',
      url: '/my-usage',
    },
    {
      title: 'Charging History',
      url: '/history',
    },
    {
      title: 'FAQ',
      url: '/faq',
    },
    {
      title: 'Support',
      url: '/support',
    },
    {
      title: 'Terms and Conditions',
      url: '/tou',
    },
    {
      title: 'Privacy Policy',
      url: '/privacy',
    },
    {
      title: 'Logout',
      url: '',
    },
  ];

  currentUser: any;
  unsubcribe = new Subject<void>();
  profileImg;
  deviceToken;
  deviceTokenNotRegistered = false;
  appCurrentVersion = '1.4.2';

  constructor(
    private router: Router,
    private userService: UserService,
    private imageUploadService: ImageUploadService,
    public platform: Platform,
    private toastController: ToastController,
    private ocpiService: OcpiService,
    private tariffService: TrariffService,
    private swUpdate: SwUpdate,
    private appVersion: AppVersion
  ) {
    this.swUpdate.available.subscribe(() => {
      window.location.reload();
    });

    this.checkAppVersion();

    this.userService
      .currentUser$()
      .pipe(takeUntil(this.unsubcribe))
      .subscribe((user) => {
        this.currentUser = user;
        console.log('currentUser:', this.currentUser);
        if (user) {
          this.imageUploadService.getImage('CUSTOMER', user.id);
          if (user.customer_type === 'CORPORATE') {
            this.appPages = this.appPages.filter(
              (item) => item.title !== 'Wallet'
            );
          }
          if (Capacitor && Capacitor.isNativePlatform()) {
            console.log('=====token result ===1111===>');
            if (this.deviceToken && !this.deviceTokenNotRegistered) {
              this.userService
                .sendDeviceToken(this.deviceToken)
                .subscribe((result) => {
                  console.log(
                    '=====token result ======>' + JSON.stringify(result)
                  );
                });
              this.deviceTokenNotRegistered = true;
            }
          }
        }
      });

    this.imageUploadService
      .geimageChangesSubject$()
      .pipe(takeUntil(this.unsubcribe))
      .subscribe((resp) => {
        if (resp && resp.category === 'CUSTOMER') {
          this.profileImg = resp.url;
        }
      });
  }

  ngOnInit(): void {
    SplashScreen.hide();
    /*
        if ((Capacitor && Capacitor.isNativePlatform())) {
            this.platform.ready().then(async () => {
                Pushy.listen();

                Pushy.setNotificationIcon('ic_notification');

                Pushy.toggleInAppBanner(true);

                Pushy.register((err, deviceToken) => {
                    // Handle registration errors
                    if (err) {
                        return alert(err);
                    }
                    console.log('=========Pushy device token:=====1=====> ' + deviceToken);
                    this.deviceToken = deviceToken;
                    if (this.currentUser && this.currentUser.id && !this.deviceTokenNotRegistered) {
                        this.userService.sendDeviceToken(this.deviceToken).subscribe((result) => {
                            console.log('=====token result ======>'+JSON.stringify(result));
                        });
                        this.deviceTokenNotRegistered = true;
                    }
                    // Display an alert with device token
                 
                   

                    // Send the token to your backend server via an HTTP GET request
                    //await fetch('https://your.api.hostname/register/device?token=' + deviceToken);

                    // Succeeded, optionally do something to alert the user
                });

                // Enable in-app notification banners (iOS 10+)
                Pushy.toggleInAppBanner(true);

                // Listen for push notifications
                Pushy.setNotificationListener(function (data) {
                    // Print notification payload data
                    // Clear iOS app badge number
                    Pushy.clearBadge();
                });

                // Listen for notification click
                Pushy.setNotificationClickListener(data => {
                    // Print notification payload data
                    Pushy.clearBadge();

                    this.userService.currentUser$().pipe(takeUntil(this.unsubcribe)).subscribe(user => {
                        if (user) {
                            this.handlePushNotification(data);
                        }
                    });
                    // execute other logic on notification click
                });
            });
        }
        */
  }

  handlePushNotification(resp: any) {
    if (resp.redirectTo === 'charging_summary') {
      this.ocpiService.fetchCDRs().subscribe((resp) => {
        let cdrId = resp.content[0] && resp.content[0].id;
        if (cdrId) {
          this.router.navigate(['billsummary', cdrId]);
        }
      });
    } else if (resp.redirectTo === 'active_session') {
      this.navigateToActiveSession();
    }
  }

  ngOnDestroy(): void {
    this.unsubcribe.next();
    this.unsubcribe.complete();
  }

  logout(): void {
    this.userService.logout();
  }

  showToast() {
    setTimeout(async () => {
      const toast = await this.toastController.create({
        message:
          'Currently no charging session is going on. Please check charging history.',
        position: 'top',
        duration: 2000,
        cssClass: 'custom-toast',
        buttons: [
          {
            text: '✖',
            role: 'cancel',
          },
        ],
      });
      toast.present();
    });
  }

  navigateToActiveSession() {
    if (this.currentUser.allow_multi_charging) {
      this.router.navigate(['/charging-ongoing-list']);
    } else {
      this.ocpiService.getSessionUpdate().subscribe((resp) => {
        if (resp && resp.data && resp.data.status === 'ACTIVE') {
          this.ocpiService.goToActiveChargingSession(resp.data);
        } else {
          this.showToast();
        }
      });
    }
  }

  onOptionClick(item: any) {
    if (item.title === 'Activie Sessions') {
      this.navigateToActiveSession();
    } else if (item.title === 'Logout') {
      this.logout();
    } else {
      this.router.navigate([item.url]);
    }
  }

  checkAppVersion = async () => {
    // console.log('platform is -- =================>', this.platform);

    if (this.platform.is('cordova')) {
      console.log('--this is the CORDOVA project--');
    }

    if (this.platform.is('capacitor')) {
      this.appCurrentVersion = await this.appVersion.getVersionNumber();

      console.log('this.appCurrentVersion----IS----->', this.appCurrentVersion);
    }
  };
}
